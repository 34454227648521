//body {
//  --tui-background-base: var(--tg-theme-bg-color, #fff);
//
//  --tui-text-primary: var(--tg-theme-text-color, #1b1f3b);
//  --tui-text-secondary: var(--tg-theme-text-color, rgba(27, 31, 59, .65));
//
//  --tui-background-accent-1: var(--tg-theme-button-color, #526ed3);
//  --tui-text-primary-on-accent-1: var(--tg-theme-button-text-color, #fff);
//
//  --tui-status-info: var(--tg-theme-hint-color, #70b6f6);
//
//  --tui-text-action: var(--tg-theme-link-color, #526ed3);
//
//  --tui-background-elevation-1: var(--tg-theme-secondary-bg-color, #fff);
//}

body {
  --tui-text-primary: #000031;
}

tui-island[tuiTheme=dark] {
  --tui-background-base: #000031;
}

.full-width {
  width: 100%;
  max-width: 100%;
}

tui-notification .t-content p {
  margin: 6px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.align-center {
  text-align: center;
}
